import React, { useState, useEffect } from 'react';
import TickBox from '../../../../components/TickBox'; // Import your custom TickBox component

const VARIABLE_TYPES = ['string', 'number', 'boolean', 'json', 'any'];

const EditVariableForm = ({ initialData, onSave, onCancel }) => {
  const [variableData, setVariableData] = useState({ ...initialData });

  useEffect(() => {
    setVariableData({ ...initialData });
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVariableData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setVariableData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSave = () => {
    onSave(variableData);
  };

  return (
    <div className="form-container">
      <h3 className="form-header">Edit Variable</h3>
      <div className="form-row">
        <div className="form-group">
          <label className="form-label">Name:</label>
          <input
            className="form-input"
            name="name"
            value={variableData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label className="form-label">Type:</label>
          <select
            className="form-input"
            name="type"
            value={variableData.type}
            onChange={handleChange}
          >
            <option value="">Select Type</option>
            {VARIABLE_TYPES.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">Default Value:</label>
        <input
          className="form-input"
          name="value"
          value={variableData.value || ''}
          onChange={handleChange}
        />
      </div>
      <div className="form-row form-checkbox-row">
        <div className="form-checkbox">
          <label>
            <TickBox
              name="isInput"
              checked={variableData.isInput}
              onChange={handleCheckboxChange}
            />
            <span>Input Variable</span>
          </label>
        </div>
        <div className="form-checkbox">
          <label>
            <TickBox
              name="isOutput"
              checked={variableData.isOutput}
              onChange={handleCheckboxChange}
            />
            <span>Output Variable</span>
          </label>
        </div>
      </div>
      <div className="toolbar">
        <button onClick={handleSave}>Save</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default EditVariableForm;
