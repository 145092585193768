// SmartDataTable.js
import React from 'react';
import DataTable from './DataTable'; // The DataGrid-based component
import VerticalTable from './VerticalTable'; // For vertical display

const SmartDataTable = ({
  data,
  rowSelectionMode = 'none', // 'none', 'single', 'multiple'
  onSelectionChange,
}) => {
  if (!data) return null;

  // Handle single object
  if (typeof data === 'object' && !Array.isArray(data)) {
    return <VerticalTable data={data} />;
  }

  // Handle array data
  if (Array.isArray(data)) {
    if (data.length === 1 && typeof data[0] === 'object') {
      // Single object in array
      return <VerticalTable data={data[0]} />;
    } else if (data.length > 0 && typeof data[0] === 'object') {
      // Array of objects
      return (
        <DataTable
          data={data}
          rowSelectionMode={rowSelectionMode}
          onSelectionChange={onSelectionChange}
        />
      );
    } else {
      // Simple list (array of strings or numbers)
      const dataArray = data.map((item, index) => ({ id: item, value: item }));
      const columns = [{ field: 'value', headerName: 'Value', width: 200 }];

      return (
        <DataTable
          data={dataArray}
          columns={columns}
          rowSelectionMode={rowSelectionMode}
          onSelectionChange={onSelectionChange}
        />
      );
    }
  }

  // Fallback for other types
  return <div>{String(data)}</div>;
};

export default SmartDataTable;
