import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  status: 'idle',
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { user } = action.payload;
      
      // Only update if user or accessToken is different
      if (!state.user || state.user?.id !== user?.id ) {
        state.user = user;
        state.status = 'succeeded';  // Set status to succeeded after user is set
        state.error = null;  // Clear any previous errors
      }
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.status = 'failed';  // Set status to failed if there's an error
    },
    clearUser: (state) => {
      state.user = null;
      state.accessToken = null;
      state.status = 'idle';  // Reset status when user is cleared
      state.error = null;  // Clear any previous errors
    },
  },
});

export const { setUser, setStatus, setError, clearUser } = userSlice.actions;
export default userSlice.reducer;
