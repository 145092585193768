import * as Yup from 'yup';

const StepTypes = {
  AI: {
    OPERATIONS: {
      generate: {
        inputSchema: Yup.object({
          prompt: Yup.string().optional(),
          context: Yup.mixed().optional(),
          aiConfig: Yup.object({
            systemInstructions: Yup.string().optional(),
            model: Yup.string().optional(),
          }).optional(),
        }),
      },
      extract: {
        inputSchema: Yup.object({
          prompt: Yup.string().optional(),
          context: Yup.mixed().optional(),
        }),
      },
    },
  },
  AppData: {
    OPERATIONS: {
      create: {
        inputSchema: Yup.object({
          entity: Yup.string().required(),
          data: Yup.object().optional(),
        }),
      },
      readOne: {
        inputSchema: Yup.object({
          entity: Yup.string().required(),
          id: Yup.mixed().required(),
          columns: Yup.mixed().optional(),
        }),
      },
      readOwned: {
        inputSchema: Yup.object({
          entity: Yup.string().required(),
          columns: Yup.mixed().optional(),
          conditions: Yup.object().optional(),
        }),
      },
      readInSpace: {
        inputSchema: Yup.object({
          entity: Yup.string().required(),
          spaceId: Yup.mixed().required(),
          columns: Yup.mixed().optional(),
          conditions: Yup.object().optional(),
        }),
      },
      update: {
        inputSchema: Yup.object({
          entity: Yup.string().required(),
          id: Yup.mixed().required(),
          data: Yup.object().required(),
        }),
      },
      delete: {
        inputSchema: Yup.object({
          entity: Yup.string().required(),
          id: Yup.mixed().optional(),
          selection: Yup.mixed().optional(),
        }),
      },
      updateMany: {
        inputSchema: Yup.object({
          entity: Yup.string().required(),
          criteria: Yup.object().required(),
          data: Yup.object().required(),
        }),
      },
      deleteMany: {
        inputSchema: Yup.object({
          entity: Yup.string().required(),
          id: Yup.mixed().optional(),
          selection: Yup.mixed().optional(),
        }),
      },
    },
  },
  SubFlow: {
    OPERATIONS: {
      execute: {
        inputSchema: Yup.object({
          subFlowId: Yup.string().required(),
        }),
      },
    },
  },
  Memory: {
    OPERATIONS: {
      read: {
        inputSchema: Yup.object({
          keys: Yup.mixed().required(),
          dataType: Yup.string().optional().default('user'),
          topN: Yup.number().integer().min(0).optional().default(0),
        }),
      },
      retrieve: {
        inputSchema: Yup.object({
          query: Yup.string().required(),
          dataType: Yup.string().optional().default('user'),
          topN: Yup.number().integer().min(0).optional().default(0),
        }),
      },
      answer: {
        inputSchema: Yup.object({
          query: Yup.string().required(),
          dataType: Yup.string().optional().default('user'),
          topN: Yup.number().integer().min(0).optional().default(0),
        }),
      },
      write: {
        inputSchema: Yup.object({
          entries: Yup.array()
            .of(
              Yup.object({
                key: Yup.string().required(),
                value: Yup.mixed().required(),
                metadata: Yup.object().optional(),
              })
            )
            .required(),
          dataType: Yup.string().optional().default('user'),
        }),
      },
      learn: {
        inputSchema: Yup.object({
          data: Yup.mixed().required(),
          dataType: Yup.string().optional().default('user'),
        }),
      },
      forget: {
        inputSchema: Yup.object({
          keys: Yup.mixed().required(),
          dataType: Yup.string().optional().default('user'),
        }),
      },
    },
  },
  UserInput: {
    OPERATIONS: {
      collect: {
        inputSchema: Yup.object({
          askAnyWay: Yup.boolean().optional(),
          inputPrompt: Yup.string().optional(),
          options: Yup.array().optional(),
        }),
      },
      select: {
        inputSchema: Yup.object({
          options: Yup.array().of(Yup.object()).required(),
          selection: Yup.mixed().optional().nullable(),
          inputPrompt: Yup.string().optional(),
        }),
      },
    },
  },
  Attachment: {
    OPERATIONS: {
      upload: {
        // Define input schema if necessary
      },
      download: {
        // Define input schema if necessary
      },
    },
  },
};

export default StepTypes;
