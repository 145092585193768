// CustomJSONViewer.js
import React from 'react';
import { useSelector } from 'react-redux';
import JSONViewer from 'react-json-view';



const AFJSONViewer = ({ src, collapsed = 1,  name = false, enableClipboard = false, displayDataTypes = false, displayObjectSize = false }) => {
    const darkMode = useSelector(state => state.theme.value === 'dark');  // Check if the current theme is 'dark'

    return (
    <JSONViewer
      src={src}
      collapsed={collapsed}
      theme={darkMode ? 'brewer' : 'rjv-default'}
      name={name}
      enableClipboard={enableClipboard}
      displayDataTypes={displayDataTypes}
      displayObjectSize={displayObjectSize}
    />
  );
};

export default AFJSONViewer;
