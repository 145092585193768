const sanitizeUserId = (userId) => {
  return userId.replace(/[^a-zA-Z0-9_-]/g, '');
};

const shortenUserId = (userId) => {
  
  return userId.replace('auth0', '').replace('|', '');
};

const generateChannelId = (flowId, userId) => {
  const sanitizedUserId = sanitizeUserId(shortenUserId(userId));
  return `${flowId}-${sanitizedUserId}`;
};

const sanitizeJsonString = (str) => {
  // Remove control characters and ensure the response is a valid JSON string
  const sanitizedStr = str.replace(/[\u0000-\u001F\u007F-\u009F]/g, '').trim();
  // Remove any extraneous text before or after the JSON
  const jsonStart = sanitizedStr.indexOf('{');
  const jsonEnd = sanitizedStr.lastIndexOf('}') + 1;
  return sanitizedStr.substring(jsonStart, jsonEnd);
};


function formatConfig(config) {
  let formattedString = `Flow Name: ${config.name}\n\nDescription: ${config.description}\n\n`;

  formattedString += `Initial Step: ${config.initialStep}\n\n`;

  formattedString += 'Steps:\n';
  for (const stepKey in config.steps) {
      const step = config.steps[stepKey];
      formattedString += `  - Step ID: ${stepKey}\n`;
      formattedString += `    Name: ${step.name}\n`;
      formattedString += `    Description: ${step.description}\n`;
      formattedString += `    Handler: ${step.handler}\n`;
      formattedString += `    Required Variables: ${step.requiredVariables.join(', ') || 'None'}\n`;
      formattedString += `    Output: ${step.output.join(', ')}\n`;
      if (step.nextSteps) {
          formattedString += `    Next Steps: ${step.nextSteps.join(', ')}\n`;
      }
      formattedString += '\n';
  }

  formattedString += 'Variables:\n';
  for (const varKey in config.variables) {
      const variable = config.variables[varKey];
      formattedString += `  - Variable ID: ${varKey}\n`;
      formattedString += `    Name: ${variable.name}\n`;
      formattedString += `    Type: ${variable.type}\n\n`;
  }

  formattedString += `Input Variables: ${config.inputVariables.join(', ')}\n\n`;
  formattedString += `Output Variables: ${config.outputVariables.join(', ')}\n`;

  return formattedString;
}





module.exports = { sanitizeUserId, generateChannelId, shortenUserId, sanitizeJsonString, formatConfig };
