// Components/SidebarToggle.js
import React from 'react';
import { PiCaretLeftLight, PiCaretRightLight } from "react-icons/pi";


const SidebarToggle = ({ toggleSidebar, isSidebarVisible }) => (
    <button className="nav-toggle" onClick={toggleSidebar}>
        {isSidebarVisible ?  <PiCaretLeftLight /> : <PiCaretRightLight /> }
    </button>
);

export default SidebarToggle;
