import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import InputArea from './InputArea';
import OutputArea from './OutputArea';
import './styles/FlowInterface.css';
import LoadingScreen from '../../../components/LoadingScreen';
import { selectFlow, selectStatus } from '../../../redux/selectors';
import { useStreamClient } from '../../../clients/StreamClient';
import { generateChannelId, sanitizeUserId } from '../../../utils/helpers';

function FlowInterface({ toggleSidebar }) {
  const [isChannelReady, setIsChannelReady] = useState(false);
  const { user, isAuthenticated, getAccessTokenSilently, isLoading: isAuthLoading } = useAuth0();
  const flow = useSelector(selectFlow);
  const status = useSelector(selectStatus);
  const { chatClient, setChannel, isUserConnected } = useStreamClient();

  useEffect(() => {
    const setupChannel = async () => {
      if (isAuthenticated && user && flow && isUserConnected) {
        try {
          const accessToken = await getAccessTokenSilently({
            audience: 'https://www.aflow.ai/api',
            scope: 'readApi',
          });

          if (accessToken) {
            const sanitizedUserId = sanitizeUserId(user.sub);
            const chanID = generateChannelId(flow.id, user.sub);

            const newChannel = chatClient.channel('messaging', chanID, {
              members: [sanitizedUserId],
              own_capabilities: ['read', 'write', 'join', 'add-members', 'AddOwnChannelMembership'],
              extra_data: { virtual_user_role: 'virtualUser-Flow' },
            });

            await newChannel.watch();
            setChannel(newChannel);
            setIsChannelReady(true);
          }
        } catch (error) {
          console.error('Error setting up channel:', error);
        }
      }
    };

    setupChannel();
  }, [isAuthenticated, user, flow, isUserConnected, getAccessTokenSilently, chatClient, setChannel]);

  

  return (
    <div className="flow-interface">
      <div className="top-container">
        <OutputArea toggleSidebar={toggleSidebar} />
        <InputArea />
      </div>
    </div>
  );
}

export default FlowInterface;
