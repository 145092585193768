import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpaceSelect from './SpaceSelect';
import FlowSelect from './FlowSelect';
import LogoutButton from './LogoutButton';
import DarkModeToggle from '../../components/DarkModeToggle';
import { setSelectedSpace } from '../../redux/slices/spaceSlice';
import { setSelectedFlow } from '../../redux/slices/flowSlice';
import '../Styles/Sidebar.css';
import { NavLink } from 'react-router-dom';
import { PiQuestionThin, PiChatTeardropTextThin } from 'react-icons/pi';
import { PiFlyingSaucerThin, PiFlowArrowThin, PiGearThin, PiDatabaseThin, PiBooksThin } from 'react-icons/pi';

const Sidebar = ({ visible, onLinkClick }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    const savedSpace = localStorage.getItem('selectedSpace');
    if (savedSpace) {
      const space = JSON.parse(savedSpace);
      dispatch(setSelectedSpace(space));

      const savedFlow = localStorage.getItem('selectedFlow');
      if (savedFlow) {
        const flow = JSON.parse(savedFlow);
        dispatch(setSelectedFlow(flow));
      }
    }
  }, [dispatch]);

  return (
    <div className={`sidebar ${visible ? 'visible' : ''}`}>
      <div className="nav-links">
        <NavLink to="/userarea/Spaces" onClick={onLinkClick}>
          <PiFlyingSaucerThin className="nav-link-icons" /> Spaces
        </NavLink>
        <SpaceSelect />
        <NavLink to="/Flows" onClick={onLinkClick}>
          <PiFlowArrowThin className="nav-link-icons" /> Flows
        </NavLink>
        <FlowSelect onFlowSelect={onLinkClick} />
        <NavLink to="/Flows" onClick={onLinkClick}>
          <PiDatabaseThin className="nav-link-icons" /> Data sources
        </NavLink>
        <NavLink to="/Flows" onClick={onLinkClick}>
          <PiBooksThin className="nav-link-icons" /> Memory
        </NavLink>
        <NavLink to="/settings" onClick={onLinkClick}>
          <PiGearThin className="nav-link-icons" /> Settings
        </NavLink>
      </div>
      <div style={{display: 'flex', width: 100, justifyContent: 'space-between', paddingBottom: 20}}>
        <DarkModeToggle />
        <LogoutButton />
      </div>
      {user && user.picture && (
        <div className="nav-icons">
          <NavLink className="user-avatar" to="/userarea/profile" onClick={onLinkClick}>
            <img src={user.picture} alt="User Avatar" className="avatar" />  <div style={{paddingLeft:10}}>{user.name}</div>
          </NavLink>
          </div>
           )}
      <div className="bottom-links">
        <NavLink to="/help" onClick={onLinkClick}>
          <PiQuestionThin /> Help
        </NavLink>
        <NavLink to="/feedback" onClick={onLinkClick}>
          <PiChatTeardropTextThin /> Feedback
        </NavLink>
      </div>
      {/* Adding user avatar link */}
    </div>
  );
};

export default React.memo(Sidebar);
