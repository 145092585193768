import React, { createContext, useContext, useState, useEffect, useRef, useMemo } from 'react';
import { StreamChat } from 'stream-chat';
import 'stream-chat-react/dist/css/index.css';
import axios from 'axios';
import PropTypes from 'prop-types';

const chatClient = StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY);

const StreamClientContext = createContext();

export const useStreamClient = () => {
  return useContext(StreamClientContext);
};

export const StreamClientProvider = ({ children, accessToken, user }) => {
  const [channel, setChannel] = useState(null);
  const [loading, setLoading] = useState(true);
  const connectUserCalled = useRef(false);

  useEffect(() => {
    const connectUser = async () => {
      try {
        if (!user || !accessToken || connectUserCalled.current) return;

        connectUserCalled.current = true;
        const validUserId = user.sub.replace(/[^a-zA-Z0-9-_@]/g, '');

        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/generate-stream-token`,
          { userId: validUserId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const streamToken = response.data.token;
        await chatClient.connectUser(
          {
            id: validUserId,
            name: user.name,
            image: user.picture,
          },
          streamToken
        );
       
        setLoading(false);
      } catch (error) {
        console.error('Error connecting to Stream:', error);
        setLoading(false);
      }
    };

    connectUser();

    return () => {
      if (chatClient) {
        chatClient.disconnectUser();
      }
    };
  }, [user, accessToken]);

  const contextValue = useMemo(
    () => ({
      chatClient,
      channel,
      setChannel,
      loading,
      isUserConnected: !loading,
    }),
    [channel, loading]
  );

  return (
    <StreamClientContext.Provider value={contextValue}>
      {children}
    </StreamClientContext.Provider>
  );
};

StreamClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
  accessToken: PropTypes.string,
  user: PropTypes.object,
};
