// FlowInterface/OutputArea.js
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Chat, Channel, Window, MessageList } from 'stream-chat-react';
import { useStreamClient } from '../../../clients/StreamClient';
import { useDispatch, useSelector } from 'react-redux';
import FlowMessage from './FlowMessage';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LoadingScreen from '../../../components/LoadingScreen';
import { selectFlow } from '../../../redux/selectors';
import { clearFlowRun } from '../../../redux/slices/flowSlice';
import FlowInfo from './Components/FlowInfo';  // Import the new component


import './styles/OutputArea.css';
import './styles/chat.css';
import ErrorBoundary from '../../../components/ErrorBoundary';
import TypingIndicatorAF from './Components/TypingIndicator';

const OutputArea = React.memo(({ isLogVisible, setIsLogVisible, toggleSidebar }) => {
  const flow = useSelector(selectFlow);
  const { chatClient, channel, loading } = useStreamClient();
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();
  const [customTypingText, setCustomTypingText] = useState('');
  const [isTypingActive, setIsTypingActive] = useState(false);

  const scrollToBottom = (ref=messagesEndRef) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  
//When there are no messages in the chat, display a message to start the conversation
const EmptyStateIndicator = () => (
  <div className="empty-chat-message">
    <p className="background-message">Start the conversation...</p>
  </div>
);

// start typing event handling
if(channel)
channel.on('typing.start', event => {
 // if(event.text)
   // console.log(`${event.text} started typing`);
  
});


// Typing event listeners
useEffect(() => {
  if (channel) {
    const handleTypingStart = (event) => {
      if (event.text) {
        setCustomTypingText(event.text); // Set custom text from event
        setIsTypingActive(true);
      }
    };

    const handleTypingStop = () => {
     // setIsTypingActive(false);
     // setCustomTypingText('');
    };

    channel.on('typing.start', handleTypingStart);
   // channel.on('typing.stop', handleTypingStop);

    return () => {
      channel.off('typing.start', handleTypingStart);
    //  channel.off('typing.stop', handleTypingStop);
    };
  }
}, [channel]);

  // Custom render function for messages
  const customRenderMessages = useCallback((options) => {
    if (options.messages.length === 0) {
      scrollToBottom();
      return <EmptyStateIndicator />;
    }

    const elements = options.messages.map((message) => (
      <CSSTransition key={message.id} timeout={800} classNames="message">
        <li>
          <FlowMessage message={message} />
        </li>
      </CSSTransition>
    ));

    return (
      <ErrorBoundary>
        <TransitionGroup component="ul">
          {elements}
        </TransitionGroup>
        <TypingIndicatorAF isTypingActive={false} customText={customTypingText} />
        <div ref={messagesEndRef} />
      </ErrorBoundary>
    );
  }, [isTypingActive, customTypingText]);

  const clearMessages = async () => {
    try {
      if (channel) {
        await channel.truncate();
        console.log('Channel truncated');
      }
    } catch (error) {
      console.error('Error truncating channel:', error);
    }
    dispatch(clearFlowRun({ flowId: flow.id }));
  };

  const toggleLogVisibility = () => {
    setIsLogVisible(!isLogVisible);
  };

  useEffect(() => {
    scrollToBottom();
  }, [channel]);

  

  return (
    <div className="output-area">
      <FlowInfo
        flow={flow}
        clearMessages={clearMessages}
        toggleLogVisibility={toggleLogVisibility}
        toggleSidebar={toggleSidebar}
      />
      <ErrorBoundary>
        <Chat client={chatClient} theme="str-chat__theme-light"  TypingIndicator={() => null}>
          <Channel channel={channel}  TypingIndicator={() => null} >
            <Window>
              <MessageList
               TypingIndicator={() => null}
                renderMessages={customRenderMessages}
                EmptyStateIndicator={EmptyStateIndicator}
              />
            </Window>
          </Channel>
        </Chat>
      </ErrorBoundary>
    </div>
  );
});

export default OutputArea;