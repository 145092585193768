import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setSelectedFlow, fetchFlows, fetchFlow } from '../../redux/slices/flowSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { selectSelectedSpace, selectSelectedFlow, selectFlows } from '../../redux/selectors';
import selectStyles from '../../utils/selectStyles';

const FlowSelect = ({ onFlowSelect }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const selectedSpace = useSelector(selectSelectedSpace, shallowEqual);
  const selectedFlow = useSelector(selectSelectedFlow, shallowEqual);
  const flows = useSelector(selectFlows, shallowEqual);
  const [flowOptions, setFlowOptions] = useState([]);

  useEffect(() => {
    const savedFlow = localStorage.getItem('selectedFlow');
    if (savedFlow) {
      dispatch(setSelectedFlow(JSON.parse(savedFlow)));
    }
  }, [dispatch]);

  useEffect(() => {
    if (selectedSpace) {
      const getFlows = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          dispatch(fetchFlows({ spaceId: selectedSpace.value, accessToken }));
        } catch (error) {
          console.error('Error fetching flows:', error);
        }
      };

      getFlows();
    }
  }, [selectedSpace, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (flows) {
      const options = flows.map((flow) => ({
        value: flow.id,
        label: flow.name,
      }));
      setFlowOptions(options);
    }
  }, [flows]);

  useEffect(() => {
    if (selectedFlow) {
      const fetchSelectedFlow = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          dispatch(fetchFlow({ flowId: selectedFlow.value, accessToken }));
        } catch (error) {
          console.error('Error fetching flow:', error);
        }
      };
      fetchSelectedFlow();
    }
  }, [selectedFlow, dispatch, getAccessTokenSilently]);

  const handleFlowChange = useCallback(
    async (selectedOption) => {
      dispatch(setSelectedFlow(selectedOption));
      localStorage.setItem('selectedFlow', JSON.stringify(selectedOption));

      const accessToken = await getAccessTokenSilently();
      dispatch(fetchFlow({ flowId: selectedOption.value, accessToken }));

      if (onFlowSelect) {
        onFlowSelect(); // This will hide the sidebar
      }
    },
    [dispatch, getAccessTokenSilently, onFlowSelect]
  );

  return (
    <Select
      value={selectedFlow}
      onChange={handleFlowChange}
      options={flowOptions}
      placeholder="Search for a flow..."
      className="selection-dropdown"
      styles={selectStyles}
    />
  );
};

export default React.memo(FlowSelect);
