// components/NodeEditor.js
// components/NodeEditor.js
import React from 'react';
import EditStepForm from './EditStepForm';
import EditVariableForm from './EditVariableForm';

const NodeEditor = ({ selectedNode, onSave, onCancel }) => {
  if (!selectedNode) return null;

  const handleSave = async (updatedData) => {
    await onSave(selectedNode.id, updatedData, selectedNode.type);
  };

  if (selectedNode.type === 'stepNode') {
    return <EditStepForm initialData={selectedNode.data} onSave={handleSave} onCancel={onCancel} />;
  }

  if (selectedNode.type === 'variableNode') {
    return <EditVariableForm initialData={selectedNode.data} onSave={handleSave} onCancel={onCancel} />;
  }

  return null;
};

export default NodeEditor;

