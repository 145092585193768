import React from 'react';
import '../styles/Header.css';
import DarkModeToggle from './DarkModeToggle';
import LoginButton from './LoginButton';
import AflowLogo from './AflowLogo';


function Header() {
  return (
    <header className={`header`}>
      <AflowLogo />
      <nav className="nav">
        <DarkModeToggle />
        <LoginButton />
      </nav>
    </header>
  );
}

export default Header;
