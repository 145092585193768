// src/utils/themeUtils.js

export const applyTheme = (theme) => {
    document.body.className = theme === 'dark' ? 'dark-mode' : 'light-mode';
  
    const themeColors = theme === 'dark' ? {
      '--primary-bg-dark': '#121212',
      '--primary-text-dark': '#f0ebde',//e0e0e0
      '--secondary-text-dark': '#645a63',
      '--accent-color-dark': '#FF4B4B',
      '--highlight-color': '#FFF',
      '--button-bg-dark': '#333',
      '--secondary-bg-dark': '#2f2f2f',
      '--button-text-dark': '#f5f5f5',
      '--link-color-dark': '#999',
      '--border-dark': '#444',
      '--border-data-dark': '#f1f1f1',
      '--icon-dark': '#ccc',
      '--error-color': '#FF4B4B',
      '--warn-color': 'orange',
      '--ok-color': '#f0ebde',//'#29b377',
      '--ok-green': '#99BE31',

    } : {
      '--primary-bg-dark': '#ffffff', //'#f1f1f1',
      '--primary-text-dark': '#333333',
      '--secondary-text-dark': '#645a63',
      '--accent-color-dark': '#FF4B4B',
      '--highlight-color': '#000',
      '--button-bg-dark': '#333',
      '--secondary-bg-dark': '#ddd',
      '--button-text-dark': '#4f4f4f',
      '--link-color-dark': '#777',
      '--border-dark': '#000',//'#ccc'
      '--border-data-dark': '#000',
      '--icon-dark': '#333',
      '--error-color': '#FF4B4B',
      '--warn-color': 'orange',
      '--ok-color': '#99BE31',//'#29b377',
      '--ok-green': '#99BE31',

    };
  
    Object.keys(themeColors).forEach(key => {
      document.body.style.setProperty(key, themeColors[key]);
    });
   // document.body.style.setProperty('--second-font', "'Roboto Mono', monospace");
   // document.body.style.setProperty('--second-font', "'apple-system, BlinkMacSystemFont', monospace");
    
  };



  // dataGridStyles.js
const dataGridStyles = {
  height: '100%',
  fontSize: 'inherit',
  '& .MuiDataGrid-root': {
    backgroundColor: 'transparent',
    color: 'var(--primary-text-dark)',
    borderColor: 'var(--primary-text-dark)',
    outline: 'none',
    fontSize: 'inherit',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-row': {
    backgroundColor: 'transparent',
    color: 'var(--primary-text-dark)',
    borderColor: 'var(--primary-text-dark)',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'var(--secondary-bg-dark)',
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'var(--secondary-bg-dark)',
    color: 'var(--accent-color-dark)',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: 'var(--primary-text-dark)',
  },
  '& .MuiTablePagination-root': {
    color: 'var(--border-dark)',
    borderColor: 'var(--primary-text-dark)',
  },
  '& .MuiTablePagination-actions': {
    color: 'var(--accent-color-dark)',
  },
  '& .MuiDataGrid-sortIcon, & .MuiDataGrid-menuIcon, & .MuiTablePagination-actions .svg': {
    color: 'var(--accent-color-dark)',
  },
  '& .MuiCheckbox-root': {
    color: 'var(--secondary-bg-dark)',
  },
  '& .MuiCheckbox-root.Mui-checked': {
    color: 'var(--primary-text-dark)',
  },
  '& .MuiButton-root': {
    color: 'var(--accent-color-dark)',
    fontSize: '8px',
  },
  '&.MuiDataGrid-root .MuiDataGrid-container--top [role=row]': {
    backgroundColor: 'var(--primary-text-dark)',
  },
};

export default dataGridStyles;

  