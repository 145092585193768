import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTheme } from '../redux/slices/themeSlice';  // Adjust the path as needed
import { MdOutlineDarkMode, MdOutlineLightMode } from 'react-icons/md'; // Import Material Icons

function DarkModeToggle() {
  const dispatch = useDispatch();
  const darkMode = useSelector(state => state.theme.value === 'dark');  // Check if the current theme is 'dark'

  const toggleDarkMode = () => {
    const newTheme = darkMode ? 'light' : 'dark';
    localStorage.setItem('theme', newTheme);  // Save the new theme to local storage
    dispatch(toggleTheme());  // Dispatch toggleTheme action
  };

  return (
    <button onClick={toggleDarkMode} className='btn' width='24px' height='24px'>
      {darkMode ? (
        <MdOutlineLightMode  /> // Show light mode icon when in dark mode
      ) : (
        <MdOutlineDarkMode  /> // Show dark mode icon when in light mode
      )}
    </button>
  );
}

export default DarkModeToggle;
