// messageService.js
export async function sendMessageToChannel(channel, message) {
    try {
        await channel.sendMessage({
            text: message,
            customType: 'text',
        });
    } catch (error) {
        console.error('Error sending message to channel:', error);
    }
}
