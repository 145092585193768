import { Extension } from '@tiptap/core';
import { Plugin } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

const CustomPlaceholder = Extension.create({
  name: 'customPlaceholder',

  addOptions() {
    return {
      placeholder: '',
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations: (state) => {
            const { doc } = state;
            const decorations = [];
            if (doc.textContent.length === 0) {
              decorations.push(
                Decoration.widget(0, () => {
                  const placeholder = document.createElement('span');
                  placeholder.classList.add('editor-placeholder', 'is-editor-empty');
                  placeholder.textContent = this.options.placeholder;
                  return placeholder;
                })
              );
            }
            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },

  addCommands() {
    return {
      setPlaceholder: (placeholder) => ({ commands }) => {
        this.options.placeholder = placeholder;
        commands.focus(); // Optionally refocus to see changes immediately
        this.editor.view.updateState(this.editor.view.state); // Update view to apply changes
        return true;
      },
    };
  },
});

export default CustomPlaceholder;
