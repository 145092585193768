import React, { useState, useEffect, useCallback } from 'react';
import { PiChatSlash, PiEye, PiEyeSlash } from 'react-icons/pi';
import FlowRunStatusIndicator from './FlowRunStatusIndicator';
import FlowVisualizer from './FlowVisualizer';
import FlowRunDisplay from './FlowRunDisplay';
import ValidationStatus from './ValidationStatus';
import { selectFlow } from '../../../../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { updateFlowRun } from '../../../../redux/slices/flowSlice';
import { runSubFlow } from '../../../../services/flowService';
import { ReactFlowProvider } from 'reactflow';
import { useStreamClient } from '../../../../clients/StreamClient';
import { sendMessageToChannel } from '../../../../services/messageService';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { PiHamburgerLight } from "react-icons/pi";


const FlowInfo = ({ clearMessages, toggleSidebar }) => {
  const dispatch = useDispatch();
  const [showVisualizer, setShowVisualizer] = useState(false);
  const [showFlowRunDisplay, setShowFlowRunDisplay] = useState(false);
  const [currentFlowId, setCurrentFlowId] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [editableName, setEditableName] = useState('');
  const flow = useSelector(selectFlow);
  const { channel } = useStreamClient();
  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    if (flow?.id !== currentFlowId) {
      setCurrentFlowId(flow?.id);
      setShowVisualizer(false);
      setShowFlowRunDisplay(false);
      setEditableName(flow?.name || '');
    }
  }, [flow?.id, flow?.name, currentFlowId]);

  const handleSaveFlow = useCallback(async (flowConfig) => {
    await sendMessageToChannel(channel, "Save new flow configuration");
    const accessToken = await getAccessTokenSilently({
      audience: 'https://www.aflow.ai/api',
      scope: 'writeApi',
    });
    setShowVisualizer(false);
    const initializeResult = await runSubFlow(
      '688515c7-040d-462d-a7f0-2ff45b0875c3',
      { id: flow.id, data: { configuration: flowConfig } },
      user.sub,
      accessToken,
      flow.id
    );
    dispatch(updateFlowRun({ flowId: flow.id, flowRun: initializeResult }));
  }, [dispatch, flow?.id, channel, getAccessTokenSilently, user.sub]);

  const handleSaveFlowName = useCallback(async () => {
    if(editableName == flow.name) return;
    await sendMessageToChannel(channel, "New flow name: " + editableName);
    const accessToken = await getAccessTokenSilently({
      audience: 'https://www.aflow.ai/api',
      scope: 'writeApi',
    });
    const updateResult = await runSubFlow(
      '688515c7-040d-462d-a7f0-2ff45b0875c3', // Replace with the appropriate subflow ID for updating the flow name
      { id: flow.id, data: { name: editableName } },
      user.sub,
      accessToken,
      flow.id
    );
    dispatch(updateFlowRun({ flowId: flow.id, flowRun: updateResult }));
  }, [ flow?.id, editableName]);

  
  const handleNameClick = () => {
    setIsEditingName(true);
  };

  const handleNameChange = (e) => {
    setEditableName(e.target.value);
  };

  const handleNameBlur = () => {
    setIsEditingName(false);
     handleSaveFlowName();
    
  };

  if (!flow) return null;

  const toggleVisualizerVisibility = () => {
    setShowFlowRunDisplay(false);
    setShowVisualizer(!showVisualizer);
  };

  const toggleFlowRunDisplayVisibility = () => {
    setShowVisualizer(false);
    setShowFlowRunDisplay(!showFlowRunDisplay);
  };

  return (
    <div className="flow-info-container">
      {flow && (
        <div className="flow-info">
          <div className="flow-info-header">
            <div className="flow-info-text" style={{ display: 'flex', alignItems: 'center' }}>
              {isEditingName ? (
                <input
                  type="text"
                  value={editableName}
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                  className="flow-name-input"
                  autoFocus
                />
              ) : (
                <h2 
                  onClick={handleNameClick}
                  className="flow-name"
                >
                  {editableName}
                </h2>
              )}
              <ValidationStatus isValidated={flow.validated} />
            </div>

            <button onClick={toggleFlowRunDisplayVisibility} title="Toggle Flow Run Display">
              <FlowRunStatusIndicator />
            </button>

            <button onClick={clearMessages} title="Clear Messages">
              <PiChatSlash  />
            </button>

            <button className="visualiserToggle" onClick={toggleVisualizerVisibility} title="Toggle Visualizer">
              {showVisualizer ? <PiEyeSlash  /> : <PiEye  />}
            </button>
            <button className="headerSideBarToggle" >
      <PiHamburgerLight  onClick={toggleSidebar} />
      </button>
          </div>
          <ErrorBoundary>
            <ReactFlowProvider>
              <div className={showVisualizer ? 'flow-info-visualizer' : 'flow-info-visualizer-hidden'}>
                <p>{flow.summary ? flow.summary : flow.description}</p>
                {showVisualizer && <FlowVisualizer flowConfig={flow.configuration} onSaveFlow={handleSaveFlow} />}
              </div>
            </ReactFlowProvider>
          </ErrorBoundary>

          {showFlowRunDisplay && <FlowRunDisplay visible={showFlowRunDisplay} />}
        </div>
      )}
    </div>
  );
};

export default FlowInfo;
