// CustomTypingIndicator.js
import React from 'react';
import { useSelector } from 'react-redux';
import { selectFlowRun } from '../../../../redux/selectors';
import { useChannelStateContext, useChatContext, useTypingContext } from 'stream-chat-react';
import '../styles/TypingIndicator.css';

const TypingIndicatorAF = ({ isTypingActive , customText, threadList }) => {
  const { typing = {} } = useTypingContext();
  const { client } = useChatContext();

  // Select flowRun status from Redux
  const flowRunStatus = useSelector((state) => selectFlowRun(state)?.status || 'undefined');

  const typingUsers = Object.values(typing).filter(
    ({ user }) => user?.id !== client.user?.id
  );
  
  if(flowRunStatus !== 'running') return null;

  return (
    <div className={`typing-indicator-container ${flowRunStatus === 'running' && !isTypingActive ? 'isTyping' : ''}`}>
     <div>
      <div className="speech-bubble">
        <p className="typing-text">{customText}</p>
        <div className="typing-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      </div>
    </div>
  );
};

export default TypingIndicatorAF;
