import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { StepNode, VariableNode } from './CustomNodes';
import JSONViewer from 'react-json-view'; 
import { initializeFlowElements } from '../../../../utils/graphLayout';
import useFlowConfig from '../../../../hooks/useFlowConfig';
import FlowViewToolbar from './FlowViewToolbar';
import NodeEditor from './NodeEditor';
import StepTypes from '../../../../utils/StepTypes'; 
import PropTypes from 'prop-types';
import AFJSONViewer from '../../../../components/AFJSONViewer';

const FlowVisualizer = ({ flowConfig, onSaveFlow }) => {
  const { localFlowConfig, updateFlowConfigNode, setLocalFlowConfig } = useFlowConfig(flowConfig);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const { fitView } = useReactFlow();
  const layoutRef = useRef(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [viewMode, setViewMode] = useState('flow'); 
  
  const updateNodeData = useCallback(
    (nodeId, updatedData) => {
      // Update the flow config node
      updateFlowConfigNode(nodeId, updatedData);

      // Nodes will re-render based on flow config
    },
    [updateFlowConfigNode]
  );

  const addMissingVariables = useCallback(
    (stepData) => {
      const { stepType, operation, inputs } = stepData;
      const inputSchema = StepTypes[stepType]?.OPERATIONS[operation]?.inputSchema;
  
      if (!inputSchema) return;
  
      setLocalFlowConfig((prevConfig) => {
        const newConfig = { ...prevConfig };
        if (!newConfig.variables) {
          newConfig.variables = {};
        }
  
        inputs.forEach((variableName) => {
          if (!newConfig.variables[variableName]) {
            // Infer the type from the inputSchema
            const field = inputSchema.fields[variableName];
            const variableType = field?.type || 'string'; // Default to 'string' if type is not found

            // Add the variable to the config
            newConfig.variables[variableName] = {
              name: variableName,
              type: variableType,
              value: '', // Default value can be empty
              isInput: true, // Mark as input variable
              isOutput: false,
            };
          }
        });
  
        return newConfig;
      });
    },
    [setLocalFlowConfig]
  );
  
  const handleSaveNode = useCallback(
    (nodeId, updatedData, nodeType) => {
      // Update the node data
      updateNodeData(nodeId, updatedData);
  
      if (nodeType === 'stepNode') {
        // Add missing variables for steps
        addMissingVariables(updatedData);
      }
  
      // Close the editor
      setSelectedNode(null);
    },
    [updateNodeData, addMissingVariables]
  );

  const handleEditNode = useCallback((id, data, nodeType) => {
    data.nodeType = nodeType; // Add nodeType to data
    const newSelectedNode = { id, data, type: nodeType };
    setSelectedNode(newSelectedNode);
  }, []);

  const nodeTypes = useMemo(
    () => ({
      stepNode: React.memo((props) => (
        <StepNode {...props} onEdit={handleEditNode}  />
      )),
      variableNode: React.memo((props) => (
        <VariableNode {...props} onEdit={handleEditNode} />
      )),
    }),
    [handleEditNode]
  );

  // Define updateLayoutFromConfig
  const updateLayoutFromConfig = useCallback((config = localFlowConfig) => {
    const { nodes: initialNodes, edges: initialEdges } = initializeFlowElements(config);
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [setNodes, setEdges]);

  // Update layout when localFlowConfig changes
  useEffect(() => {
    updateLayoutFromConfig(localFlowConfig);
  }, [localFlowConfig, updateLayoutFromConfig]);

  // Fit view after nodes and edges are updated
  useEffect(() => {
    if (nodes.length > 0 && edges.length > 0) {
      fitView();
    }
  }, [nodes, edges, fitView]);

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
      const { source, target } = params;
  
      // First, update the flow config
      if (localFlowConfig.variables[source]) {
        // Source is a variable node connected to a step node
        updateFlowConfigNode(target, {
          inputs: [...(localFlowConfig.steps[target].inputs || []), source],
        });
      } else if (localFlowConfig.variables[target]) {
        // Step node connected to a variable node as output
        updateFlowConfigNode(source, {
          outputs: [...(localFlowConfig.steps[source].outputs || []), target],
        });
      } else {
        // Connecting two step nodes
        updateFlowConfigNode(source, {
          nextSteps: [...(localFlowConfig.steps[source].nextSteps || []), target],
        });
      }
  
      // No need to call updateLayoutFromConfig manually here
    },
    [localFlowConfig, setEdges, updateFlowConfigNode]
  );

  const onDeleteNode = useCallback((nodeId) => {
    // Remove the node from the config
    setLocalFlowConfig((prevConfig) => {
      const newConfig = { ...prevConfig };
  
      // Delete from steps or variables
      if (newConfig.steps[nodeId]) {
        delete newConfig.steps[nodeId];
      } else if (newConfig.variables[nodeId]) {
        delete newConfig.variables[nodeId];
      }
  
      // Clean up references to this node in nextSteps
      Object.keys(newConfig.steps).forEach((stepId) => {
        const step = newConfig.steps[stepId];
        if (step.nextSteps) {
          newConfig.steps[stepId].nextSteps = step.nextSteps.filter((nextStepId) => nextStepId !== nodeId);
        }
      });
  
      return newConfig;
    });
  
    // Nodes and edges will be re-rendered based on localFlowConfig
  }, [setLocalFlowConfig]);

  const onDeleteEdge = useCallback((edgeId) => {
    setLocalFlowConfig((prevConfig) => {
      const newConfig = { ...prevConfig };
      const [source, target] = edgeId.split('-');
  
      // Remove the target from the source's nextSteps
      if (newConfig.steps[source] && newConfig.steps[source].nextSteps) {
        newConfig.steps[source].nextSteps = newConfig.steps[source].nextSteps.filter(
          (nextStepId) => nextStepId !== target
        );
      }
  
      // Remove target from the source's outputs if it's a variable connection
      if (newConfig.steps[source] && newConfig.steps[source].outputs) {
        newConfig.steps[source].outputs = newConfig.steps[source].outputs.filter((outputId) => outputId !== target);
      }
  
      // Remove the source from the target's inputs
      if (newConfig.steps[target] && newConfig.steps[target].inputs) {
        newConfig.steps[target].inputs = newConfig.steps[target].inputs.filter((inputId) => inputId !== source);
      }
  
      return newConfig;
    });
  
    // Edges will be re-rendered based on localFlowConfig
  }, [setLocalFlowConfig]);

  // Handle node changes incrementally
  const handleNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => {
        const updatedNodes = applyNodeChanges(changes, nds);
  
        changes.forEach((change) => {
          if (change.type === 'remove') {
            // Call onDeleteNode when a node is removed
            onDeleteNode(change.id);
          } else if (change.type === 'stepNode' || change.type === 'variableNode') {
            // Update flow config for non-removal changes
            updatedNodes.forEach((node) => {
              updateFlowConfigNode(node.id, node.data);
            });
          }
        });
  
        return updatedNodes;
      });
    },
    [updateFlowConfigNode, onDeleteNode]
  );

  // Handle edge changes incrementally
  const handleEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => {
        const updatedEdges = applyEdgeChanges(changes, eds);
  
        changes.forEach((change) => {
          if (change.type === 'remove') {
            // Call onDeleteEdge when an edge is removed
            onDeleteEdge(change.id);
          }
        });
  
        return updatedEdges;
      });
    },
    [onDeleteEdge]
  );

  // Adding new step
  const addNewStep = useCallback(() => {
    const newStepId = `Step_${Date.now()}`;
    const newStep = {
      id: newStepId,
      type: 'stepNode',
      data: {
        name: 'New Step',
        stepType: '',
        operation: '',
        stepConfig: {},
        inputs: [],
        outputs: [],
        nextSteps: [],
      },
      position: { x: 0, y: 0 },
    };
  
    // Update flow config
    setLocalFlowConfig((prevConfig) => {
      const newFlowConfig = { ...prevConfig };
  
      if (!newFlowConfig.steps) {
        newFlowConfig.steps = {};
      }
  
      newFlowConfig.steps[newStepId] = { ...newStep.data };
      return newFlowConfig;
    });
  }, [setLocalFlowConfig]);

  // Adding new variable
  const addNewVariable = useCallback(() => {
    const newVarId = `Var_${Date.now()}`;
    const newVariable = {
      id: newVarId,
      type: 'variableNode',
      data: {
        name: 'New Variable',
        type: '',
        value: '',
        isInput: false,
        isOutput: false,
      },
      position: { x: 0, y: 0 },
    };
  
    // Update flow config
    setLocalFlowConfig((prevConfig) => {
      const newFlowConfig = { ...prevConfig };
  
      if (!newFlowConfig.variables) {
        newFlowConfig.variables = {};
      }
  
      newFlowConfig.variables[newVarId] = { ...newVariable.data };
      return newFlowConfig;
    });
  }, [setLocalFlowConfig]);

  // Save flow
  const handleSaveFlow = useCallback(() => {
    if (onSaveFlow) {
      onSaveFlow(localFlowConfig);
    }
  }, [onSaveFlow, localFlowConfig]);

  // Toggle view mode
  const toggleView = useCallback(() => {
    setViewMode((prevMode) => (prevMode === 'flow' ? 'json' : 'flow'));
  }, []);

  const proOptions = { hideAttribution: true };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <FlowViewToolbar
        toggleView={toggleView}
        viewMode={viewMode}
        addNewStep={addNewStep}
        addNewVariable={addNewVariable}
        handleSaveFlow={handleSaveFlow}
      />
      <NodeEditor
        selectedNode={selectedNode}
        onSave={handleSaveNode}
        onCancel={() => setSelectedNode(null)}
      /> 
      {viewMode === 'flow' ? (
        <ReactFlowProvider>
          {nodes.length > 0 && edges.length > 0 && (
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={handleNodesChange}
              onEdgesChange={handleEdgesChange}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              proOptions={proOptions}
            >
              <Controls />
              <Background />
            </ReactFlow>
          )}
        </ReactFlowProvider>
      ) : (
        <AFJSONViewer src={localFlowConfig} enableClipboard = {true} />
       
      )}
    </div>
  );
};

FlowVisualizer.propTypes = {
  flowConfig: PropTypes.object.isRequired,
  onSaveFlow: PropTypes.func.isRequired,
};

export default FlowVisualizer;
