// src/services/userService.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api';

// Create an instance of axios for API requests
const api = axios.create({
    baseURL: API_BASE_URL
});

// Function to synchronize user information with the database
export async function syncUserWithDatabase(auth0User, accessToken) {
    try {
        // Find user by Auth0 ID
        const userFromDb = await findUserByAuth0Id(auth0User.sub, accessToken);
        if (!userFromDb) {
            // If user doesn't exist, create a new one
            const newUser = {
                auth0_id: auth0User.sub,
                email: auth0User.email,
                name: auth0User.name,
                picture: auth0User.picture
            };
            return await insertUser(newUser, accessToken);
        }
        return userFromDb;
    } catch (error) {
        console.error('Error syncing user with database:', error);
        throw error;
    }
}

// Function to find user by Auth0 ID
async function findUserByAuth0Id(auth0Id, accessToken) {
    try {
        const response = await api.get(`/users/${auth0Id}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;  // User found, return the data
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.log('User not found, returning null to handle creation logic.');
            return null;  // User not found, return null to indicate this
        }
        console.error('Error finding user by Auth0 ID:', error);
        throw error;  // Re-throw the error if it's not a 404
    }
}


// Function to insert a new user into the database
async function insertUser(newUser, accessToken) {
    try {
        const response = await api.post(`/users`, newUser, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error inserting new user:', error);
        throw error;
    }
}
