// FlowMessage.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch and useSelector
import { useAuth0 } from '@auth0/auth0-react';
import flowIcon from '../../../images/aflow-icon-sq-anim.png';
import { shortenUserId } from '../../../utils/helpers';
import { PiArrowBendDownRightFill } from "react-icons/pi";
import SmartDataTable from './Components/SmartDataTable'; // Updated to use SmartDataTable
import { updateFlowRun, setCurrentInputRequirement } from '../../../redux/slices/flowSlice';
import { selectFlowRun, selectCurrentInputRequirement, selectCurrentInputRequirementType } from '../../../redux/selectors'; // Import selectCurrentInputRequirement
import { fetchFlowRunVariables } from '../../../services/flowService';
import { setInputValue } from '../../../redux/slices/inputSlice'; // Import setInputValue action
import './styles/chat.css';
import { CheckBox } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';




const valueToString = (value) => {
  if (typeof value === 'string') return value;
  if (value && typeof value === 'object') return JSON.stringify(value, null, 2);
  return String(value);
};


const FlowMessage = React.memo((props) => {
  const message = props.message || props;
  const { user } = useSelector((state) => state.user);
  const { getAccessTokenSilently } = useAuth0();
  const userClass = message.user
    ? shortenUserId(message.user.id) === shortenUserId(user.sub)
      ? 'me'
      : 'others'
    : 'system';
  const isFlowMessage = message.user?.id?.startsWith('flow-');
  const flowRun = useSelector(selectFlowRun);
  const currentInputRequirement = useSelector(selectCurrentInputRequirement);
  const currentInputRequirementType = useSelector(selectCurrentInputRequirementType);

  const dispatch = useDispatch(); // Initialize dispatch
  const previousCustomTypeRef = useRef();
  const [variables, setVariables] = useState(
    Array.isArray(message.outputVariables) ? message.outputVariables : []
  );

  useEffect(() => {
    const previousCustomType = previousCustomTypeRef.current;

    const fetchVariables = async () => {
      try {
        if (message.outputVariables) {
          setVariables(message.outputVariables);
        } else if ((flowRun?.status === 'pending' || flowRun.status === 'completed') && message.flowRun_id === flowRun?.flowRunId) {
          const accessToken = await getAccessTokenSilently({
            audience: 'https://www.aflow.ai/api',
            scope: 'readApi',
          });
          const fetchedVariables = await fetchFlowRunVariables(
            message.flowRun_id,
            message.outputVariablesNames,
            accessToken
          );
          setVariables(Array.isArray(fetchedVariables) ? fetchedVariables : []);
        }
      } catch (error) {
        console.error('Error fetching flow run variables:', error);
      }
    };

    if (message.outputVariablesNames && message.flowRun_id) {
      fetchVariables();
    }
   
    if (message.flow_id && message.flowRun_id) {
      if (message.customType === 'FlowCompleted' && previousCustomType !== 'FlowCompleted') {
        dispatch(
          updateFlowRun({
            flowId: message.flow_id,
            flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'completed' },
          })
        );
      } else if (message.customType === 'InputRequired' && previousCustomType !== 'InputRequired') {
        dispatch(
          updateFlowRun({
            flowId: message.flow_id,
            flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'pending' },
          })
        );
        dispatch(
          setCurrentInputRequirement({
            flowId: message.flow_id,
            inputRequirement: message.inputRequired,
          })
        );
      } else if (message.customType === 'FlowFailed' && previousCustomType !== 'FlowFailed') {
        dispatch(
          updateFlowRun({
            flowId: message.flow_id,
            flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'failed' },
          })
        );
      }
    }

    previousCustomTypeRef.current = message.customType;
  }, [dispatch, flowRun, getAccessTokenSilently, message]);

  const renderFormattedOutput = useCallback(
    (outputArray) => {
      const outputs = Array.isArray(outputArray) ? outputArray : [outputArray];
      return outputs.map((output, index) => {
        const isCurrentFlowPending =
          flowRun?.status === 'pending' && message.flowRun_id === flowRun?.flowRunId;

        // Determine if this output corresponds to the current input requirement
        const inputKey = Array.isArray(currentInputRequirement)
          ? currentInputRequirement[0]
          : currentInputRequirement;
       
       
        const rowSelectionMode = isCurrentFlowPending 
          ? output.type === 'jsonArray'
            ? 'multiple'
            : 'single'
          : 'none';

        return (
          <div key={index}>
            {output?.type === 'json' || output?.type === 'jsonArray' ? (
              <SmartDataTable
                data={output.value || output}
                rowSelectionMode={rowSelectionMode}
                onSelectionChange={(selected) => {
                  // Handle selection
                  if(currentInputRequirementType === 'string')
                    dispatch(setInputValue({ key: inputKey, value: selected[0].value }));
                  else
                  dispatch(setInputValue({ key: inputKey, value: selected }));
                }}
              />
            ) : output?.type === 'boolean' ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckBox checked={output.value} />
                {output.value ? 'Yes' : 'No'}
              </div>
            ) : output?.value ? (
              <div style={{ marginBottom: '10px' }}>
                <ReactMarkdown>{valueToString(output.value)}</ReactMarkdown>
              </div>
            ) : null}
          </div>
        );
      });
    },
    [flowRun, message.flowRun_id, currentInputRequirement, dispatch]
  );

  const renderContent = () => {
    if (message.customType === 'message.date') {
      return <p>{new Date(message.date).toLocaleString()}</p>;
    }

    const outputs = Array.isArray(variables) && variables.length > 0 ? variables : [message.text];
    const len = message.variablesLength;
    const minHeight = len > 100 ? 500 : len * 10;

    if (message.customType === 'FlowCompleted') {
      return <div style={{ minHeight }}>{renderFormattedOutput(outputs)}</div>;
    }

    if (message.customType === 'InputRequired') {
      const requiredVariables = message.inputRequired || [];
      return (
        <div style={{ minHeight }}>
          {renderFormattedOutput(outputs)}
          <p>
            {typeof message.inputPrompt === 'object' ? (
              <SmartDataTable data={message.inputPrompt} rowSelectionMode="none" />
            ) : (
              message.inputPrompt || 'Please provide the following:'
            )}
          </p>
          {requiredVariables.map((input, index) => (
            <div key={index} className="message-input">
              <PiArrowBendDownRightFill />
              <div className="input-text">
                <strong>{input}</strong>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return <>
    {isHTML(message.text) ? (
                // Render the HTML content safely if it contains HTML tags
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.text) }} />
            ) : (
                // Render plain text if it doesn't contain HTML tags
                <p>{message.text.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}</p>
            )}
    
  </>;
  };

  return (
    <>
    <div className={`message-${userClass}`} key={message.id}>
      {isFlowMessage ? (
        <img src={flowIcon} alt="Flow Bot" className="avatar" />
      ) : (
        message.user?.image && (
          <img
            src={message.user.image}
            alt={message.user.name}
            className="avatar"
          />
        )
      )}
      <div className={`message-inner-${userClass}`}>{renderContent()}</div>


    </div>
    <div className="message-notes">{message.tokenCount && message.tokenCount > 0 ? `${message.tokenCount}  tokens ✧˖°` : null}</div>

    </>
  );
});

export default FlowMessage;


function isHTML(content) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1); // Node type 1 represents element nodes
}