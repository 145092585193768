import React from 'react';
import { Handle, Position } from 'reactflow';
import JSONViewer from 'react-json-view';
import { FiEdit } from 'react-icons/fi'; // Import edit icon from react-icons

const StepNode = React.memo(({ id, data, onEdit }) => {
  // Handle edit button click to trigger editing
  const handleEditClick = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up to node selection
    if (onEdit) {
      onEdit(id, data, 'stepNode'); // Pass id and data directly to onEdit
    }
  };

  return (
    <div className="step-node">
      <div className="step-node-header">
        <h4>{data?.name || 'Unnamed Step'}</h4>
        <p>
          {data?.stepType || 'Unknown'}.{data?.operation || 'Unknown'}
        </p>
        <button className="edit-button" onClick={handleEditClick}>
          <FiEdit />
        </button>
        <p>
          <small>
            Config:
            <JSONViewer
              src={data?.stepConfig || {}}
              name={false}
              theme="monokai:inverted"
              collapsed={0}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          </small>
        </p>
      </div>
      <div className="step-node-body">
        <p>
          <strong>Inputs:</strong> {data?.inputs?.join(', ') || 'None'}
        </p>
        <p>
          <strong>Outputs:</strong> {data?.outputs?.join(', ') || 'None'}
        </p>
      </div>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </div>
  );
});

const VariableNode = React.memo(({ id, data, onEdit, updateNodeData }) => {
  // Handle edit button click to trigger editing
  const handleEditClick = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up to node selection
    if (onEdit) {
      onEdit(id, data, 'variableNode'); // Pass id and data directly to onEdit
    }
  };

  return (
    <div
      className={`variable-node ${data?.isInput ? 'input-variable' : ''} ${
        data?.isOutput ? 'output-variable' : ''
      }`}
    >
      <div className="variable-node-header">
        <h4>{data?.name || 'Unnamed Variable'}</h4>
        <p>
          <strong>ID:</strong> {id}
        </p>
        <button className="edit-button" onClick={handleEditClick}>
          <FiEdit />
        </button>
      </div>
      <div className="variable-node-body">
        <p>
          <strong>Type:</strong> {data?.type || 'Unknown'}
        </p>
        {data?.value && (
          <p>
            <strong>Default Value:</strong> {data.value}
          </p>
        )}
      </div>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </div>
  );
});

export { StepNode, VariableNode };
