import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSpace, fetchSpaces } from '../../redux/slices/spaceSlice';
import { setSelectedFlow, fetchFlow  } from '../../redux/slices/flowSlice';

import { useAuth0 } from '@auth0/auth0-react';
import { selectSelectedSpace, selectSpaces } from '../../redux/selectors';
import selectStyles from '../../utils/selectStyles';

const SpaceSelect = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const selectedSpace = useSelector(selectSelectedSpace);
  const spaces = useSelector(selectSpaces);
  const [spaceOptions, setSpaceOptions] = useState([]);

  useEffect(() => {
    const savedSpace = localStorage.getItem('selectedSpace');
    if (savedSpace) {
      dispatch(setSelectedSpace(JSON.parse(savedSpace)));
    }
  }, [dispatch]);

  useEffect(() => {
    const getSpaces = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(fetchSpaces(accessToken));
      } catch (error) {
        console.error('Error fetching spaces:', error);
      }
    };

    if (spaces.length === 0) {
      getSpaces();
    }
  }, [spaces.length, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (spaces) {
      const options = spaces.map((space) => ({
        value: space.id,
        label: space.name,
      }));
      setSpaceOptions(options);
    }
  }, [spaces]);

  const handleSpaceChange = (selectedOption) => {
    dispatch(setSelectedSpace(selectedOption));
    localStorage.setItem('selectedSpace', JSON.stringify(selectedOption));
    dispatch(setSelectedFlow(null));
    dispatch(fetchFlow(null));
  };

  return (
    <Select
      value={selectedSpace}
      onChange={handleSpaceChange}
      options={spaceOptions}
      placeholder="Search for a space..."
      className="selection-dropdown"
      styles={selectStyles}
    />
  );
};

export default React.memo(SpaceSelect);
