import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    inputValues: {},
};

const inputSlice = createSlice({
    name: 'input',
    initialState,
    reducers: {
        setInputValue: (state, action) => {
            const { key, value } = action.payload;
            state.inputValues[key] = value;
        },
        clearInputValue: (state, action) => {
            const key = action.payload.key;
            delete state.inputValues[key];
        },
        clearInputValues: (state) => {
            state.inputValues = {};
        },
    },
});

export const { setInputValue, clearInputValue, clearInputValues } = inputSlice.actions;

export default inputSlice.reducer;
