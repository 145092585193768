import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Sidebar from './Components/Sidebar';
import Header from './Components/Header';
import SidebarToggle from './Components/SidebarToggle';
import './Styles/UserArea.css';
import { syncUserWithDatabase } from '../services/userService';
import { useAuth0 } from '@auth0/auth0-react';
import FlowInterface from './Components/FlowInterface';
import { setUser, setStatus, setError } from '../redux/slices/userSlice';
import { selectFlow, selectSelectedFlow } from '../redux/selectors';
import { StreamClientProvider } from '../clients/StreamClient';
import LoadingScreen from '../components/LoadingScreen';

function UserArea() {
    const dispatch = useDispatch();
    const { isAuthenticated, getAccessTokenSilently, user: auth0User, isLoading } = useAuth0();

    const flow = useSelector(selectFlow, shallowEqual);
    const selectedFlow = useSelector(selectSelectedFlow, shallowEqual);
    const { user, status } = useSelector((state) => state.user, shallowEqual);
    const flowStatus = useSelector((state) => state.flow.status);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [accessToken, setAccessToken] = useState(null);

    const toggleSidebar = useCallback(() => {
        setIsSidebarVisible((prevState) => !prevState);
    }, []);

    // Fetch and set user data
    useEffect(() => {
        const fetchAndSetUser = async () => {
            if (isAuthenticated && auth0User && !user) {
                try {
                    dispatch(setStatus('loading'));

                    const token = await getAccessTokenSilently({
                        audience: 'https://www.aflow.ai/api',
                        scope: 'readApi',
                    });
                    setAccessToken(token);

                    await syncUserWithDatabase(auth0User, token);

                    dispatch(setUser({ user: auth0User }));
                    dispatch(setStatus('succeeded'));
                } catch (error) {
                    console.error('Error syncing user with database:', error);
                    dispatch(setError({ error: error.message }));
                    dispatch(setStatus('failed'));
                }
            }
        };

        fetchAndSetUser();
    }, [isAuthenticated, auth0User, user, getAccessTokenSilently, dispatch]);

    
    // Show sidebar if flow is null after loading
    useEffect(() => {
        if(selectedFlow) {  setIsSidebarVisible(false)}
        else
        if (status === 'succeeded' && flowStatus === 'idle' && !selectedFlow) {
            setIsSidebarVisible(true);
        }
    }, [status, selectedFlow, flowStatus]);

    if (!isAuthenticated) {
        return <LoadingScreen message="Please log in to access this area." />;
    }

    if (isLoading || status !== 'succeeded') {
        return <LoadingScreen message="Getting things ready..." />;
    }

    if (status === 'failed') {
        return <LoadingScreen message="Error loading user data." />;
    }

    

    return (
        <StreamClientProvider accessToken={accessToken} user={auth0User}>
            <div className="user-area">
                <Header toggleSidebar={toggleSidebar} />
                <div className="container">
                    <div className="sidebar-container">
                        <div className={`sidebar-toggle ${isSidebarVisible ? 'visible' : ''}`} >
                        
                            <SidebarToggle toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} />
                        </div>
                        <Sidebar visible={isSidebarVisible} onLinkClick={toggleSidebar} />
                    </div>
                    {!selectedFlow && isSidebarVisible ? <div className='sidebar-toggle-text'> Please select a flow... </div> : <FlowInterface toggleSidebar={toggleSidebar}/> }
                </div>
            </div>
        </StreamClientProvider>
    );
}

export default UserArea;
