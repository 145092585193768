import React, { useState, useEffect } from 'react';
import _ from 'lodash'; // Import lodash for kebab case conversion
import StepTypes from '../../../../utils/StepTypes'; // Adjust the import path
import PropTypes from 'prop-types'; // Import PropTypes for props validation

const EditStepForm = ({ initialData, onSave, onCancel }) => {
  const [stepData, setStepData] = useState(() => ({
    name: initialData.name || '',
    id: _.kebabCase(initialData.name || ''), // Automatically generate the id from name
    stepType: initialData.stepType || '',
    operation: initialData.operation || '',
    stepConfig: initialData.stepConfig || {},
    inputs: initialData.inputs || [],
    output: initialData.output || [],
  }));

  const [validationError, setValidationError] = useState(null);

  useEffect(() => {
    setStepData({
      name: initialData.name || '',
      id: _.kebabCase(initialData.name || ''), // Generate id when initial data changes
      stepType: initialData.stepType || '',
      operation: initialData.operation || '',
      stepConfig: initialData.stepConfig || {},
      inputs: initialData.inputs || [],
      output: initialData.output || [],
    });
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setStepData((prevData) => {
      // Update id when name changes and convert it to kebab case
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      if (name === 'name') {
        updatedData.id = _.kebabCase(value); // Update id to match kebab-case of name
      }
      return updatedData;
    });
  };

  const handleConfigChange = (e) => {
    const { name, value } = e.target;
    setStepData((prevData) => ({
      ...prevData,
      stepConfig: {
        ...prevData.stepConfig,
        [name]: value,
      },
    }));
  };

  const handleParameterChange = (fieldName, useVariable) => {
    setStepData((prevData) => {
      let updatedInputs = [...prevData.inputs];
      let updatedStepConfig = { ...prevData.stepConfig };

      if (useVariable) {
        // Remove from stepConfig and add to inputs
        updatedInputs.push(fieldName);
        delete updatedStepConfig[fieldName];
      } else {
        // Remove from inputs and initialize in stepConfig
        updatedInputs = updatedInputs.filter((input) => input !== fieldName);
        updatedStepConfig[fieldName] = '';
      }

      return {
        ...prevData,
        inputs: updatedInputs,
        stepConfig: updatedStepConfig,
      };
    });
  };

  const validateStepData = async () => {
    const { stepType, operation, stepConfig, inputs } = stepData;
    const schema = StepTypes[stepType]?.OPERATIONS[operation]?.inputSchema;
    if (!schema) {
      setValidationError('Invalid stepType or operation');
      return false;
    }

    const errors = [];

    for (const fieldName of Object.keys(schema.fields)) {
      const field = schema.fields[fieldName];
      const isRequired = field.spec?.optional === false;
      const usesVariable = inputs.includes(fieldName);
      const configValue = stepConfig[fieldName];

      if (isRequired) {
        if (usesVariable) {
          // Variable will be provided at runtime; no need to validate value
          continue;
        } else if (configValue === undefined || configValue === '') {
          errors.push(`Value for "${fieldName}" is required.`);
        } else {
          try {
            await field.validate(configValue);
          } catch (err) {
            errors.push(`Invalid value for "${fieldName}": ${err.errors.join(', ')}`);
          }
        }
      }
    }

    if (errors.length > 0) {
      setValidationError(errors.join(' '));
      return false;
    }

    setValidationError(null);
    return true;
  };

  const handleSave = async () => {
    if (await validateStepData()) {
      console.log('Saving step data:', stepData);
      await onSave(stepData);
    }
  };

  // **Move renderStepConfigInputs inside EditStepForm**
  const renderStepConfigInputs = (stepType, operation, stepConfig, inputs) => {
    const schema = StepTypes[stepType]?.OPERATIONS[operation]?.inputSchema;
    if (!schema) return null;

    const fields = schema.fields;
    return Object.keys(fields).map((fieldName) => {
      const field = fields[fieldName];
      const fieldType = field.type;
      const isRequired = field.spec?.optional === false;

      const usesVariable = inputs.includes(fieldName);
      const configValue = stepConfig[fieldName] || '';

      return (
        <div key={fieldName} className="form-group">
          <label className="form-label">
            {fieldName}: {isRequired && <span className="required">*</span>}
          </label>
          <div className="parameter-row">
            <select
              value={usesVariable ? 'variable' : 'value'}
              onChange={(e) =>
                handleParameterChange(fieldName, e.target.value === 'variable')
              }
              className="form-input"
            >
              <option value="variable">Use Variable</option>
              <option value="value">Use Value</option>
              
            </select>
            {usesVariable ? (
              <input
                type="text"
                placeholder="Variable Name"
                value={fieldName} // Variable name is the field name
                disabled
                className="form-input"
              />
            ) : (
              <input
                name={fieldName}
                type={fieldType === 'number' ? 'number' : 'text'}
                value={configValue}
                onChange={handleConfigChange}
                className="form-input"
              />
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div>
   
    <div className="form-container">
      {/* Header with Step Name */}
      <h3 className="form-header">
        Edit Step:{' '}
        <input
          className="form-input"
          name="name"
          value={stepData.name}
          onChange={handleChange}
          placeholder="Enter step name"
          style={{ fontSize: '1rem', width: '70%', border: 'none' }}
        />
      </h3>

      {/* Step Type and Operation in a single row */}
      <div className="form-row">
        <div className="form-group">
          <label className="form-label">
            Step Type: <span className="required">*</span>
          </label>
          <select
            className="form-input"
            name="stepType"
            value={stepData.stepType}
            onChange={handleChange}
          >
            <option value="">Select Step Type</option>
            {Object.keys(StepTypes).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label className="form-label">
            Operation: <span className="required">*</span>
          </label>
          <select
            className="form-input"
            name="operation"
            value={stepData.operation}
            onChange={handleChange}
          >
            <option value="">Select Operation</option>
            {Object.keys(StepTypes[stepData.stepType]?.OPERATIONS || {}).map((op) => (
              <option key={op} value={op}>
                {op}
              </option>
            ))}
          </select>
        </div>
      </div>

      {stepData.operation && (
        <div className="form-group">
          <label className="form-label">Parameters:</label>
          {renderStepConfigInputs(
            stepData.stepType,
            stepData.operation,
            stepData.stepConfig,
            stepData.inputs
          )}
        </div>
      )}

      <div className="form-group">
        <label className="form-label">
          Outputs (comma-separated): <span className="required">*</span>
        </label>
        <input
          className="form-input"
          name="output"
          value={stepData.output.join(', ')}
          onChange={(e) =>
            setStepData({ ...stepData, output: e.target.value.split(',').map((s) => s.trim()) })
          }
        />
      </div>

      {validationError && <p className="error">{validationError}</p>}

      <div className="toolbar">
        <button onClick={handleSave}>Save</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
    </div>
  );
};

EditStepForm.propTypes = {
  initialData: PropTypes.shape({
    name: PropTypes.string,
    stepType: PropTypes.string,
    operation: PropTypes.string,
    stepConfig: PropTypes.object,
    inputs: PropTypes.arrayOf(PropTypes.string),
    output: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditStepForm;
