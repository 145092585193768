// Hero.js
import React from 'react';
import '../styles/Hero.css';
import LoginButton from './LoginButton';

function Hero() {
  return (
    <section className="hero">
      <br/>
      <br/>
      
      
      <h1>Hola, नमस्ते, bonjour, 안녕하세요, olá, Hej!</h1>
      <LoginButton />
      
    </section>
  );
}

export default Hero;
