// hooks/useFlowConfig.js
import { useState, useCallback } from 'react';
import { initializeFlowElements } from '../utils/graphLayout';

const useFlowConfig = (initialConfig) => {
  const [localFlowConfig, setLocalFlowConfig] = useState(() =>
    JSON.parse(JSON.stringify(initialConfig))
  );

 const updateFlowConfigNode = useCallback((nodeId, updatedData) => {
  setLocalFlowConfig((prevConfig) => {
    const newConfig = { ...prevConfig };

    if (newConfig.steps[nodeId]) {
      newConfig.steps[nodeId] = {
        ...newConfig.steps[nodeId],
        ...updatedData,
      };
    } else if (newConfig.variables[nodeId]) {
      newConfig.variables[nodeId] = {
        ...newConfig.variables[nodeId],
        ...updatedData,
      };
    }

    return newConfig;
  });
}, []);

  
  return { localFlowConfig, updateFlowConfigNode, setLocalFlowConfig };
};

export default useFlowConfig;
