// utils/graphLayout.js
import dagre from 'dagre';
import ReactFlow, {
    Position,
  } from 'reactflow';

const nodeWidth = 250;
const nodeHeight = 80;

export const getLayoutedElements = (nodes, edges) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'LR', nodesep: 100, ranksep: 25 });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: node.width || nodeWidth,
      height: node.height || nodeHeight,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x,
        y: nodeWithPosition.y,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};

export const initializeFlowElements = (localFlowConfig) => {
    const initialNodes = [];
    const initialEdges = [];
    const { steps, variables, inputVariables, outputVariables } = localFlowConfig;
  
    if (!steps || Object.keys(steps).length === 0) return { nodes: [], edges: [] };
  
    Object.keys(steps).forEach((stepId) => {
        const step = steps[stepId];
        initialNodes.push({
          id: stepId,
          type: 'stepNode',
          data: {
            ...step,
          },
          position: { x: 0, y: 0 },
        });

        // Add edges for next steps
        if (step.nextSteps) {
          step.nextSteps.forEach((nextStepId) => {
            initialEdges.push({
              id: `${stepId}-${nextStepId}`,
              source: stepId,
              target: nextStepId,
              type: 'default',
              sourcePosition: Position.Right,
              targetPosition: Position.Left,
              markerEnd: { type: 'arrowclosed' },
            });
          });
        }

        // Add edges for step inputs
        if (step.inputs) {
          step.inputs.forEach((input) => {
            initialEdges.push({
              id: `${input}-${stepId}`,
              source: input,
              target: stepId,
              type: 'default',
              sourcePosition: Position.Left,
              targetPosition: Position.Right,
              markerEnd: { type: 'arrowclosed' },
              animated: true,
            });
          });
        }

        // Add edges for step outputs
        if (step.outputs) {
          step.outputs.forEach((output) => {
            initialEdges.push({
              id: `${stepId}-${output}`,
              source: stepId,
              target: output,
              type: 'default',
              sourcePosition: Position.Right,
              targetPosition: Position.Left,
              markerEnd: { type: 'arrowclosed' },
              animated: true,
            });
          });
        }
      });

      Object.keys(variables).forEach((variableName) => {
        const variable = variables[variableName];
        const isInput = inputVariables.includes(variableName);
        const isOutput = outputVariables.includes(variableName);

        initialNodes.push({
          id: variableName,
          type: 'variableNode',
          data: {
            id: variableName,
            ...variable,
            isInput,
            isOutput,
            // Removed updateNodeData from data
          },
          position: { x: 0, y: 0 },
        });
      });
  
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(initialNodes, initialEdges);
  
    return { nodes: layoutedNodes, edges: layoutedEdges };
  };