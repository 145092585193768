// DataTable.js
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import dataGridStyles from '../../../../utils/themeUtils';
import JSONViewer from 'react-json-view'; // or another JSON viewer
import { format } from 'date-fns'; // for date formatting
import TickBox from '../../../../components/TickBox';
import { CheckBox } from '@mui/icons-material';

const DataTable = ({
  data,
  columns,
  rowSelectionMode = 'none',
  onSelectionChange,
  defaultPageSize = 100,
  rowHeight = 36,
}) => {
  if (!data || data.length === 0) return null;

  const calculateDynamicWidth = (key, data) => {
    // Determine data type by inspecting the first row
    const sampleValue = data[0][key];
    const isUUID = /^[0-9a-fA-F-]{36}$/.test(sampleValue); // Simple UUID pattern
    const isNumeric = !isNaN(sampleValue);
    const isShortString = typeof sampleValue === 'string' && !/\s/.test(sampleValue) && sampleValue.length < 15;
  
    if (isUUID) return 50; // Fixed width for UUIDs
    if (isNumeric) return 100; // Narrower width for numeric values
    if (isShortString) return 150; // Moderate width for short strings without spaces
  
    // For other text, calculate width based on length of longest string
    const maxLength = data.reduce((acc, row) => {
      const value = row[key] ? row[key].toString().length : 0;
      return Math.max(acc, value);
    }, 0);
  
    return Math.min(Math.max(maxLength * 10, 150), 400); // General fallback width
  };

  const formatCellValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      // Render object using JSONViewer if it's an object
      return <JSONViewer src={value} name={false} theme="ocean" collapsed={true} displayObjectSize={false} />;
    } else if (Date.parse(value)) {
      // Format dates if the value is a date
      return format(new Date(value), 'yyyy-MM-dd HH:mm'); // Customize date format as needed
    }  else if (typeof value === 'boolean') {
      // Boolean values as Yes/No or custom
      return <CheckBox src={value} />
    }
    // Fallback for strings, numbers, etc.
    return value;
  };
  
  
  // Generate columns if not provided
  const generatedColumns = columns || Object.keys(data[0]).map((key) => ({
    field: key,
    headerName: key.charAt(0).toUpperCase() + key.slice(1),
    width: calculateDynamicWidth(key, data), // Dynamic width based on data
  minWidth: 100,
  maxWidth: 400,
  renderCell: (params) => formatCellValue(params.value), // Dynamic render logic
  resizable: true, // enable resizing

  }));

  const handleSelectionChange = (selectionModel) => {
    
    if (onSelectionChange) {
      const selectedData = data.filter((row) => selectionModel.includes(row.id));
      console.log('selectedData:', selectedData);
      onSelectionChange(selectedData);
    }
  };

  const enableSelection = rowSelectionMode !== 'none';
  const gridHeight = Math.min(
    data.length * rowHeight + 74 - (data.length <= defaultPageSize ? rowHeight : 0),
    500
  );

  return (
    <div style={{ width: '100%', maxWidth:'100vh',display: 'flex', height: gridHeight }}>
      <DataGrid
        rows={data}
        columns={generatedColumns}
        pageSize={defaultPageSize}
        checkboxSelection={enableSelection}
        disableSelectionOnClick={!enableSelection}
        disableMultipleRowSelection={rowSelectionMode !== 'multiple'}
        onRowSelectionModelChange={handleSelectionChange}
        hideFooter={data.length <= defaultPageSize}
        sx={dataGridStyles}
        density="compact"
        getRowId={(row) => row.id || row.key || row.someOtherUniqueField || `${Math.random()}`}

      />
    </div>
  );
};

export default DataTable;
