import { createSelector } from 'reselect';

// Flow state selector
const selectFlowState = (state) => state.flow;
const selectInputState = (state) => state.input;
const selectUserState = (state) => state.user;

// User selectors
export const selectUser = createSelector([selectUserState], (user) => user.user);

// Flow selectors
export const selectFlow = createSelector([selectFlowState], (flow) => flow.flow);
export const selectFlows = createSelector([selectFlowState], (flow) => flow.flows);
export const selectSelectedFlow = createSelector([selectFlowState], (flow) => flow.selectedFlow);
export const selectFlowStatus = createSelector([selectFlowState], (flow) => flow.status);
export const selectFlowError = createSelector([selectFlowState], (flow) => flow.error);

// FlowRun selectors
export const selectFlowRun = createSelector(
  [selectFlowState],
  (flowState) => flowState.flowRuns[flowState.selectedFlow?.value] || {}
);

export const selectFlowRunStatus = createSelector(
  [selectFlowRun],
  (flowRun) => flowRun.status || 'undefined'
);

export const selectCurrentInputRequirement = createSelector(
  [selectFlowRun],
  (flowRun) => flowRun.currentInputRequirement || {}
);
export const selectCurrentInputRequirementType = createSelector(
  [selectFlowRun],
  (flowRun) => flowRun.currentInputRequirementType || {}
);

export const selectCurrentInputParameterName = createSelector(
  [selectCurrentInputRequirement, selectFlow],
  (currentInputRequirement, flow) => {
    if (!currentInputRequirement || !flow?.configuration?.variables) {
      return '...';
    }

    const variableId = Array.isArray(currentInputRequirement)
      ? currentInputRequirement[0]
      : currentInputRequirement;

    const variable = flow.configuration.variables[variableId];

    return variable?.name || '...';
  }
);

// Input selectors
export const selectInput = createSelector([selectInputState], (input) => input.inputValues.command || '');
export const selectInputValues = createSelector([selectInputState], (input) => input.inputValues);
export const selectLog = createSelector([selectInputState], (input) => input.log);
export const selectStatus = createSelector([selectInputState], (input) => input.status);
export const selectMessages = createSelector([selectInputState], (input) => input.messages);

// Space selectors
const selectSpaceState = (state) => state.space;
export const selectSpaces = createSelector([selectSpaceState], (space) => space.spaces);
export const selectSelectedSpace = createSelector([selectSpaceState], (space) => space.selectedSpace);
export const selectSpaceStatus = createSelector([selectSpaceState], (space) => space.status);
export const selectSpaceError = createSelector([selectSpaceState], (space) => space.error);

// theme selectors
const selectThemeState = (state) => state.theme;
export const selectTheme = createSelector([selectThemeState], (theme) => theme.value);